import { useState } from 'react';
import dynamic from 'next/dynamic';
import { useScrollPosition } from '@src/utils/hooks';
import { StyledHeader } from './header.styled';

const Head = dynamic(() => import('./Head'), { ssr: true });
const Menu = dynamic(() => import('./Menu'), { ssr: true });

const Header = ({ handleSC }) => {
    const [hideOnScroll, setHideOnScroll] = useState(true);
    const [currPosition, setCurrPosition] = useState(0);

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y;
        handleSC(currPos.y);
        setCurrPosition(currPos.y);

        if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    }, [hideOnScroll]);

    return (
        <StyledHeader position={currPosition}>
            <Head />
            <Menu />
        </StyledHeader>
    );
};

export default Header;
