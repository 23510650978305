import styled from 'styled-components';
import { rgba } from 'polished';

// eslint-disable-next-line import/prefer-default-export
export const StyledHeader = styled.header`
    margin: 0 0 1rem;

    ${({ position }) => position < -100 && `
        box-shadow: 0 1px 5px 0 ${rgba('#9bc8f280', 0.5)};
    `}

    > .menu {
        ${({ position }) => position < -100 && `
            display: none;
        `}
    }
`;
